import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { getPropertieDetailBySlug } from "../../store/properties";
import * as Yup from "yup";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Loader from "./../../components/Loader/Loader";

import { FiFacebook, FiTwitter } from "react-icons/fi";
import { BsEnvelope } from "react-icons/bs";
import { ImPinterest2 } from "react-icons/im";

import { FaUsersCog } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { BsFillTelephoneFill } from "react-icons/bs";

import { getInteriorFacility } from "../../components/common/interiorFacility";
import { getCommonFacility } from "../../components/common/commonFacility";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { postEnquiryDetail } from "../../store/enquiry";
import { Alert, Spinner } from "react-bootstrap";
import { message as toast } from "antd";
import "./View.scss";

let initialValues = {
  name: null,
  email: null,
  mobile: null,
  message: null,
  info: null,
  url: null,
};

const imgURL = process.env.REACT_APP_IMAGE_URL;

const mobileRegExp = /^[0-9]{10}$/;
const nameRegExp = /^[A-Za-z\s]*$/;

const validationSchema = Yup.object({
  info: Yup.string(),
  name: Yup.string()
    .matches(nameRegExp, "Invalid characters in name")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Required !"),
  email: Yup.string().email("Invalid email format!").required("Required !"),
  mobile: Yup.string()
    .matches(mobileRegExp, "Mobile number is not valid")
    .required("Required !"),
  message: Yup.string(),
});

function View() {
  const params = useParams();
  const dispatch = useDispatch();
  const [propertie, setPropertie] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [messageApi, contextHolder] = toast.useMessage();

  const toaster = (status, msg) => {
    messageApi.open({
      type: status === 200 ? "sucess" : "error",
      content: msg,
    });
  };

  const showDescription = (data) => {
    return parse(data);
  };

  const onSubmit = (values) => {
    setLoader(true);
    console.log(values, "=> request form");
    if (values) {
      dispatch(postEnquiryDetail(values)).then((res) => {
        console.log("res ", res);
        setLoader(false);
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 3000);
      });
    }
  };

  useEffect(() => {
    const slug = params?.name;
    dispatch(getPropertieDetailBySlug(slug))
      .then((res) => {
        initialValues.info = res[0].data?.title;
        initialValues.url = window.location.href;
        setPropertie(res[0].data);
        setLoading(false);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e?.response, "view res");
        toaster(e?.response?.status, e?.response?.data?.errors);
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <>
      <div className="innerpage-wrapper">
        {contextHolder}
        {!loading && propertie ? (
          <div className="mainSection-wrapper">
            <Container className="innerPage py-3">
              <div className="innerPage__section1 d-flex">
                <div className="section1__left">
                  {/* <div className="title py-2">
                    <h4>{propertie?.title}</h4>
                  </div> */}
                  <div className="carousel">
                    <Carousel
                      showArrows={true}
                      autoPlay={true}
                      nextArrow={
                        <button type="button" class="slick-next">
                          Next
                        </button>
                      }
                      infiniteLoop={true}
                    >
                      {propertie?.images?.map((res) => {
                        return (
                          <div>
                            <img src={`${imgURL}${res}`} alt={res} />
                          </div>
                        );
                      })}
                    </Carousel>
                    {/* <div className="social-icons d-flex justify-content-between">
                    <button
                      onClick={() =>
                        (window.location.href = "https://www.facebook.com/chennaiofficespace")
                      }
                    >
                      <FiFacebook /> Facebook
                    </button>
                    <button
                      onClick={() =>
                        (window.location.href = "https://twitter.com/login")
                      }
                    >
                      <FiTwitter /> Twitter
                    </button>
                    <button
                      onClick={() =>
                        (window.location.href = "https://in.pinterest.com/")
                      }
                    >
                      <ImPinterest2 /> Pinterst
                    </button>
                    <button>
                      <BsEnvelope />
                      Email
                    </button>
                  </div> */}
                  </div>

                  <div className="innerPage__section2">
                    <div className="details d-grid">
                      {/* <div className="details__title">
                      {propertie?.amenities && (
                        <>
                          <strong>Interior facility :</strong>
                          <ul>
                            {propertie.amenities?.map((res) => {
                              return getInteriorFacility(res);
                            })}
                          </ul>
                        </>
                      )}
                    </div> */}
                      {/* <div className="details__title">
                      <strong>Common facility :</strong>
                      <ul>
                        {propertie.amenities?.map((res) => {
                          return getCommonFacility(res);
                        })}
                      </ul>
                    </div> */}
                      <div className="details__title">
                        <strong>Details :</strong>
                        <ul className="commercial">
                          {/* {propertie?.title && (
                            <>
                              <li key="1">
                                <strong>Title</strong>
                              </li>
                              <li key="2">{propertie?.title}</li>
                            </>
                          )} */}
                          {propertie?.propertyType && (
                            <>
                              <li key="3">
                                <strong>Property Type:</strong>
                              </li>
                              <li key="4">{propertie?.propertyType}</li>
                            </>
                          )}
                          {/* {propertie?.ranking && (
                          <li key="3">
                            <strong>Ranking:</strong> {propertie?.ranking}
                          </li>
                        )} */}
                          {/* {propertie?.isFeatured && (
                          <li key="4">
                            <strong>Featured:</strong> {propertie?.isFeatured}
                          </li>
                        )} */}
                          {propertie?.propertyFacing && (
                            <>
                              <li key="5">
                                <strong>Property Facing:</strong>
                              </li>
                              <li key="6">{propertie?.propertyFacing}</li>
                            </>
                          )}
                          {propertie?.workspaceType && (
                            <>
                              <li key="7">
                                <strong>Work Space Type:</strong>
                              </li>
                              <li key="8">{propertie?.workspaceType}</li>
                            </>
                          )}
                          {/* {propertie?.buildingName && (
                          <li key="7">
                            <strong>Building Name:</strong>{" "}
                            {propertie?.buildingName}
                          </li>
                        )} */}
                          {/* {propertie?.latitude && (
                          <li key="8">
                            <strong>Latitude:</strong> {propertie?.latitude}
                          </li>
                        )}
                        {propertie?.longitude && (
                          <li key="9">
                            <strong>Longitude:</strong> {propertie?.longitude}
                          </li>
                        )} */}
                          {propertie?.totalArea && (
                            <>
                              <li key="9">
                                <strong>Total Area:</strong>
                              </li>
                              <li key="10">{propertie?.totalArea}</li>
                            </>
                          )}
                          {/* {propertie?.ownershipStructure && (
                          <li key="11">
                            <strong>Ownership Structure:</strong>{" "}
                            {propertie?.ownershipStructure}
                          </li>
                        )}
                        {propertie?.developerLandlordName && (
                          <li key="12">
                            <strong>Developer Landlord Name:</strong>
                            {propertie?.developerLandlordName}
                          </li>
                        )} */}
                          {/* {propertie?.propertyDescription && <li key="1"><strong>
                      Property Description:</strong> {propertie?.propertyDescription}
                    </li>} */}
                          {/* {propertie?.microMarket && (
                          <li key="13">
                            <strong>MicroMarket:</strong>{" "}
                            {propertie?.microMarket}
                          </li>
                        )} */}
                          {/*  {propertie?.amenities && <li key="1"><strong>{propertie?.amenities}</li>} */}
                          {/* {propertie?.perSqftRate && (
                          <li key="14">
                            <strong>PerSqft / Rate:</strong>{" "}
                            {propertie?.perSqftRate}
                          </li>
                        )} */}
                          {propertie?.rentalValue && (
                            <>
                              <li key="11">
                                <strong>Rental Value:</strong>
                              </li>
                              <li key="12">{propertie?.rentalValue}</li>
                            </>
                          )}
                          {/* {propertie?.floorDescription && (
                          <li key="16">
                            <strong>Floor Description:</strong>{" "}
                            {propertie?.floorDescription}
                          </li>
                        )}
                        {propertie?.yearBuilt && (
                          <li key="17">
                            <strong>Year Built:</strong> {propertie?.yearBuilt}
                          </li>
                        )}
                        {propertie?.storey && (
                          <li key="18">
                            <strong>Storey:</strong> {propertie?.storey}
                          </li>
                        )}
                        {propertie?.typicalFloorArea && (
                          <li key="19">
                            <strong>Typical Floor Area:</strong>{" "}
                            {propertie?.typicalFloorArea}
                          </li>
                        )}
                        {propertie?.parkingRatio && (
                          <li key="20">
                            <strong>Parking Ratio:</strong>{" "}
                            {propertie?.parkingRatio}
                          </li>
                        )}
                        {propertie?.powerBackup && (
                          <li key="21">
                            <strong>Power Backup:</strong>{" "}
                            {propertie?.powerBackup}
                          </li>
                        )}
                        {propertie?.airConditioning && (
                          <li key="22">
                            <strong>Air Conditioning:</strong>{" "}
                            {propertie?.airConditioning}
                          </li>
                        )}
                        {propertie?.transport && (
                          <li key="23">
                            <strong>Transport:</strong> {propertie?.transport}
                          </li>
                        )}
                        {propertie?.nearestParking && (
                          <li key="24">
                            <strong>Nearest Parking:</strong>{" "}
                            {propertie?.nearestParking}
                          </li>
                        )} */}
                          {/* {propertie?.facilities && (
                          <li key="25">
                            <strong>Facilities:</strong> {propertie?.facilities}
                          </li>
                        )}
                        {propertie?.internalParking && (
                          <li key="26">
                            <strong>Internal Parking:</strong>{" "}
                            {propertie?.internalParking}
                          </li>
                        )}
                        {propertie?.nearbyAmenities && (
                          <li key="27">
                            <strong>Nearby Amenities:</strong>{" "}
                            {propertie?.nearbyAmenities}
                          </li>
                        )} */}
                          {propertie?.locality && (
                            <>
                              <li key="13">
                                <strong>Address:</strong>
                              </li>
                              <li key="14">
                                {propertie?.locality &&
                                  `${propertie?.locality?.locationName}, ${propertie?.locality?.district}, ${propertie?.locality?.state}: ${propertie?.locality?.pincode}`}
                              </li>
                            </>
                          )}
                        </ul>
                        {/* <div className="details__title">
                        <strong>
                          Please feel free to contact for more details and site
                          visit.
                        </strong>
                        <ul>
                          <li key="1">
                            <FaUsersCog /> Gopi Krishnan
                          </li>
                          <li key="2">
                            <FaUserFriends /> Property Consultant
                          </li>
                          <li key="3">
                            <ImOffice /> Chennai Commercials
                          </li>
                          <li key="4">
                            <BsFillTelephoneFill />{" "}
                            <Link to="tel:9171766660">91717 66660</Link>
                          </li>
                        </ul>
                      </div> */}
                      </div>
                    </div>

                    <div className="details__title mt-4">
                      <strong>Description :</strong>
                    </div>
                    {propertie?.propertyDescription &&
                      showDescription(propertie.propertyDescription)}
                  </div>
                </div>
                <div className="section1__right">
                  {loader ? (
                    <>
                      <div className="contact_form_loader">
                        <Spinner animation="border" variant="info" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="filterForm mb-3">
                        <div className="title">
                          <h4>{propertie?.title}</h4>
                        </div>
                        <div className="filterForm__bottom pt-2">
                          <small>
                            {propertie?.locality &&
                              `${propertie?.locality?.locationName}, ${propertie?.locality?.district}, ${propertie?.locality?.state}: ${propertie?.locality?.pincode}`}
                          </small>
                        </div>
                      </div>
                      {/* <div className="title py-2">
                      <h4>Request More Information</h4>
                      <p>
                        Your email address will not be published. Required
                        fields are marked <span>*</span>
                      </p>
                    </div>
                    <Alert show={show} variant="success">
                      Message sent Successfully.
                    </Alert>
                    <div className="innerPage__form">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        <Form>
                          <div
                            className="form-control py-2"
                            style={{ display: "none" }}
                          >
                            <label htmlFor="property">Info</label>
                            <Field disabled type="text" name="info" value="" />
                          </div>

                          <div className="form-control">
                            <label htmlFor="name">
                              Name <span>*</span>
                            </label>
                            <Field type="text" name="name" id="name" />
                            <ErrorMessage name="name">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-control">
                            <label htmlFor="email">
                              Email <span>*</span>{" "}
                            </label>
                            <Field type="email" name="email" id="email" />
                            <ErrorMessage name="email">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-control">
                            <label htmlFor="mobile">
                              mobile <span>*</span>{" "}
                            </label>
                            <Field name="mobile" id="mobile" />
                            <ErrorMessage name="mobile">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-control">
                            <label htmlFor="message">
                              Message <span>*</span>{" "}
                            </label>
                            <Field id="message" name="message" as="textarea" />
                            <ErrorMessage name="message">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="from-control">
                            <button type="submit" onSubmit={onSubmit}>
                              submit
                            </button>
                          </div>
                        </Form>
                      </Formik>
                    </div> */}
                    </>
                  )}

                  {propertie?.latitude && propertie?.longitude && (
                    <div className="filterForm">
                      <div className="filterForm__bottom">
                        <p>
                          <strong>Posted in</strong>
                        </p>
                        <small className="mb-3">
                          {propertie?.locality &&
                            `${propertie?.locality?.locationName}, ${propertie?.locality?.district}, ${propertie?.locality?.state}: ${propertie?.locality?.pincode}`}
                        </small>
                      </div>
                      <div className="gmap-wrapper">
                        <iframe
                          title="google map location"
                          src={`https://maps.google.com/maps?q=${propertie?.latitude},${propertie?.longitude}&hl=es;z=14&output=embed`}
                        ></iframe>
                        {/* <iframe title="google map location" src="https://maps.google.com/maps?q=10.305385,77.923029&hl=es;z=14&amp;output=embed"></iframe> */}

                        {/* <iframe title="google map location" src={`https://maps.google.com/maps?q=${propertie?.latitude},${propertie?.longitude}&hl=es;z=14&output=embed`}></iframe> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
        ) : loading && !propertie ? (
          <Loader />
        ) : (
          <div> </div>
        )}
      </div>
    </>
  );
}

export default View;
